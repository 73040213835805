import './index.css'
import Uploader from './uploader'

/**
 * Timeout when loader should be removed
 */
const LOADER_DELAY = 500

/**
 * @typedef {object} PersonalityToolData
 * @description Personality Tool's input and output data format
 * @property {string} description - person's description
 * @property {string} link - link to person's website
 * @property {string} photo - person's photo url
 */

/**
 * @typedef {object} PersonalityConfig
 * @description Config supported by Tool
 * @property {object} endpoints - upload endpoints
 * @property {string} endpoints.byFile - upload by file
 * @property {string} endpoints.byUrl - upload by URL
 * @property {string} field - field name for uploaded image
 * @property {string} types - available mime-types
 * @property {string} descriptionPlaceholder - description placeholder
 * @property {string} linkPlaceholder - link placeholder
 * @property {object} additionalRequestData - any data to send with requests
 * @property {object} additionalRequestHeaders - allows to pass custom headers with Request
 * @property {object} [uploader] - optional custom uploader
 * @property {function(File): Promise.<UploadResponseFormat>} [uploader.uploadByFile] - method that upload image by File
 * @property {function(string): Promise.<UploadResponseFormat>} [uploader.uploadByUrl] - method that upload image by URL
 */

/**
 * @typedef {object} UploadResponseFormat
 * @description This format expected from backend on file uploading
 * @property {number} success - 1 for successful uploading, 0 for failure
 * @property {object} file - Object with file data.
 *                           'url' is required,
 *                           also can contain any additional data that will be saved and passed back
 * @property {string} file.url - [Required] image source URL
 */

/**
 * Personality Tool for the Editor.js
 */
export default class ImageAndText {
  /**
   * @param {PersonalityToolData} data - Tool's data
   * @param {PersonalityConfig} config - Tool's config
   * @param {API} api - Editor.js API
   */
  constructor({ data, config, api }) {
    this.api = api

    this.nodes = {
      wrapper: null,
      description: null,
      photo: null,
    }

    this.config = {
      endpoints: config.endpoints || '',
      additionalRequestData: config.additionalRequestData || {},
      additionalRequestHeaders: config.additionalRequestHeaders || {},
      field: config.field || 'image',
      types: config.types || 'image/*',
      descriptionPlaceholder: config.descriptionPlaceholder || 'Description',
      uploader: config.uploader || undefined,
    }

    /**
     * Set saved state
     */
    this._data = {}
    this.data = data

    /**
     * Module for image files uploading
     */
    this.uploader = new Uploader({
      config: this.config,
      onUpload: (response) => this.onUpload(response),
      onError: (error) => this.uploadingFailed(error),
    })
  }

  /**
   * Get Tool toolbox settings
   * icon - Tool icon's SVG
   * title - title to show in toolbox
   */
  static get toolbox() {
    return {
      icon:
        '<svg width="13" height="14" xmlns="http://www.w3.org/2000/svg">\n' +
        '    <path d="M5.27 7.519a3.114 3.114 0 0 1-1.014-.44 3.354 3.354 0 0 1-.973-1.002C2.865 5.42 2.65 4.62 2.65 3.8c0-.82.215-1.62.633-2.277.251-.394.574-.737.973-1.002a3.094 3.094 0 0 1 3.438 0c.399.265.722.608.973 1.002.418.657.633 1.456.633 2.277 0 .82-.215 1.62-.633 2.277a3.353 3.353 0 0 1-.973 1.002c-.31.206-.655.357-1.023.442.93.054 1.826.212 2.591.45.503.155.95.345 1.324.576.27.167.511.358.725.6a2.441 2.441 0 0 1-.109 3.408c-.25.247-.525.424-.828.568-.38.181-.816.311-1.32.413-.853.172-1.937.264-3.079.264-1.142 0-2.226-.092-3.078-.264-.505-.102-.941-.232-1.321-.413a2.969 2.969 0 0 1-.828-.568 2.449 2.449 0 0 1-.13-3.384c.21-.246.45-.441.717-.61a5.63 5.63 0 0 1 1.316-.587c.77-.243 1.675-.403 2.618-.455zM5.974 5.5c.594 0 1.075-.761 1.075-1.7s-.481-1.7-1.075-1.7S4.9 2.861 4.9 3.8s.481 1.7 1.075 1.7zm0 6.05c2.057 0 3.725-.336 3.725-.75S8.007 9.75 5.95 9.75s-3.7.636-3.7 1.05c0 .414 1.668.75 3.725.75z" id="a"/>\n' +
        '</svg>',
      title: 'Image and Text',
    }
  }

  /**
   * File uploading callback
   * @param {UploadResponseFormat} response
   */
  onUpload(response) {
    if (response.success && response.file) {
      const { url, file } = response.file
      Object.assign(this.data, { photo: url, file })
      this.showFullImage()
    } else {
      this.uploadingFailed('Incorrect response: ' + JSON.stringify(response))
    }
  }

  /**
   * On success: remove loader and show full image
   */
  showFullImage() {
    setTimeout(() => {
      this.nodes.photo.classList.remove(this.CSS.loader)
      this.nodes.photo.src = this.data.photo
    }, LOADER_DELAY)
  }

  /**
   * On fail: remove loader and reveal default image placeholder
   */
  stopLoading() {
    setTimeout(() => {
      this.nodes.photo.classList.remove(this.CSS.loader)
      this.nodes.photo.removeAttribute('style')
    }, LOADER_DELAY)
  }

  /**
   * Show loader when file upload started
   */
  addLoader() {
    this.nodes.photo.style.background = 'none'
    this.nodes.photo.classList.add(this.CSS.loader)
    this.nodes.photo.classList.remove(this.CSS.photoPreview)
  }

  /**
   * If file uploading failed, remove loader and show notification
   * @param {string} errorMessage -  error message
   */
  uploadingFailed(errorMessage) {
    this.stopLoading()

    this.api.notifier.show({
      message: errorMessage,
      style: 'error',
    })
  }

  /**
   * Tool's CSS classes
   */
  get CSS() {
    return {
      baseClass: this.api.styles.block,
      input: this.api.styles.input,
      loader: this.api.styles.loader,

      /**
       * Tool's classes
       */
      wrapper: 'cdx-personality',
      photo: 'cdx-personality__photo',
      photoPreview: 'cdx-personality__photo-preview',
      photoWrapper: 'cdx-personality__photo-wrapper',
      description: 'cdx-personality__description',
    }
  }

  /**
   * Return Block data
   * @param {HTMLElement} toolsContent
   * @return {PersonalityToolData}
   */
  save(toolsContent) {
    const description = toolsContent.querySelector(`.${this.CSS.description}`).innerHTML

    Object.assign(this.data, {
      description: description ?? this._data.description,
    })

    return this.data
  }

  /**
   * Stores all Tool's data
   * @param {PersonalityToolData} data
   */
  set data({ name, description, link, photo, file }) {
    this._data = Object.assign(
      {},
      {
        description: description ?? this._data.description,
        photo: photo ?? this._data.photo,
        file: file ?? this._data.file,
      },
    )
  }

  /**
   * Return Tool data
   * @return {PersonalityToolData} data
   */
  get data() {
    return this._data
  }

  /**
   * Renders Block content
   * @return {HTMLDivElement}
   */
  render() {
    const { description, photo } = this.data

    this.nodes.photoWrapper = this.make('div', this.CSS.photoWrapper)
    this.nodes.wrapper = this.make('div', this.CSS.wrapper)

    this.nodes.description = this.make('div', this.CSS.description, {
      contentEditable: true,
    })

    const imgClasses = [this.CSS.photo]
    if (!photo) {
      imgClasses.push(this.CSS.photoPreview)
    }
    this.nodes.photo = this.make('img', imgClasses)

    if (photo) {
      this.nodes.photo.src = photo
    }

    if (description) {
      this.nodes.description.innerHTML = description
    } else {
      this.nodes.description.dataset.placeholder = this.config.descriptionPlaceholder
    }

    this.nodes.photo.addEventListener('click', () => {
      this.uploader.uploadSelectedFile({
        onPreview: () => {
          this.addLoader()
        },
      })
    })

    this.nodes.photoWrapper.appendChild(this.nodes.photo)
    this.nodes.wrapper.appendChild(this.nodes.photoWrapper)
    this.nodes.wrapper.appendChild(this.nodes.description)

    return this.nodes.wrapper
  }

  /**
   * Helper method for elements creation
   * @param tagName
   * @param classNames
   * @param attributes
   * @return {HTMLElement}
   */
  make(tagName, classNames = null, attributes = {}) {
    const el = document.createElement(tagName)

    if (Array.isArray(classNames)) {
      el.classList.add(...classNames)
    } else if (classNames) {
      el.classList.add(classNames)
    }

    for (const attrName in attributes) {
      el[attrName] = attributes[attrName]
    }

    return el
  }
}
