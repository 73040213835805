import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import Drawer from '@mui/material/Drawer'
import { searchPostTimeline } from 'api/timelines'
import { useDrawersContext } from 'components/context/ClientsDrawersProvider'
import FailPlaceholder from 'components/placeholders/FailPlaceholder'
import { ShadowContainer } from 'components/ShadowContainer'
import { defaultDrawerSX } from 'const'
import { UIItemContentContainer, UIStatusBadge } from 'features/UI'
import React from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { routes } from 'routes/routes'
import { ArticleType, ETimelinePostStatus, ETimelinePostType, TimelineSearchResult } from 'types'
import { getRoute, isDefined } from 'utils'
import DrawerContainer from '../common/DrawerContainer'
import DrawerHeader from '../common/DrawerHeader'

interface Props {
  isOpen: boolean
  searchQuery: string
  handleClose: () => void
}

interface ResultProps {
  id: number
  title: string
  type: ETimelinePostType
  timelineId: number | null
  status: ETimelinePostStatus
}

const boxSX = {
  display: 'inline-block',
  paddingX: 1,
  paddingY: 0.5,
  borderRadius: 1,
  background: '#964599',
  alignSelf: 'flex-end',
  color: 'white',
}

type IPostTypeMap = {
  [key in ETimelinePostType]?: {
    articleType: ArticleType
    label: JSX.Element
  }
}

const PostTypeMap: IPostTypeMap = {
  [ETimelinePostType.careArticle]: {
    articleType: ArticleType.CareArticle,
    label: (
      <Box sx={boxSX}>
        <Typography variant="body2">Timeline Post</Typography>
      </Box>
    ),
  },
  [ETimelinePostType.lessons]: {
    articleType: ArticleType.Lesson,
    label: (
      <Box sx={{ ...boxSX, background: '#99285F' }}>
        <Typography variant="body2">Lesson</Typography>
      </Box>
    ),
  },
  [ETimelinePostType.learnArticles]: {
    articleType: ArticleType.LearnLibrary,
    label: (
      <Box sx={{ ...boxSX, background: '#99285F' }}>
        <Typography variant="body2">Learn Article</Typography>
      </Box>
    ),
  },
}

const SearchResultItem = (props: ResultProps) => {
  const navigate = useNavigate()
  const { title, id, type, status } = props

  const { handleCloseSearchDrawer } = useDrawersContext()

  const onClick = () => {
    const articleType = PostTypeMap[type]?.articleType
    if (!isDefined(articleType)) return

    let route = getRoute(routes.journeyItem, {
      journeyItemType: articleType,
      journeyItemId: id,
    })

    if (articleType === ArticleType.LearnLibrary) {
      route = getRoute(routes.learnLibraryItem, {
        learnLibraryItemType: ArticleType.LearnLibrary,
        learnLibraryItemId: id,
      })
    }

    handleCloseSearchDrawer()
    navigate(route)
  }

  return (
    <ShadowContainer sx={{ height: '100%' }}>
      <UIItemContentContainer spacing={2} justifyContent="space-between" onClick={onClick}>
        <Typography fontWeight={400} variant="h6" className="text-max-lines-3">
          {title}
        </Typography>
        <Stack spacing={1.5} alignItems="flex-end">
          <UIStatusBadge status={status} />
          {PostTypeMap[type]?.label || null}
          <Button variant="outlined" sx={{ alignSelf: 'flex-end' }}>
            Learn More
          </Button>
        </Stack>
      </UIItemContentContainer>
    </ShadowContainer>
  )
}

const SearchResultDrawer = (props: Props) => {
  const { isOpen, searchQuery, handleClose } = props

  const query = useQuery(
    ['searchPostTimeline', { searchQuery }],
    () => searchPostTimeline(searchQuery),
    {
      enabled: isOpen,
      select: (req) => req.data,
      cacheTime: 0,
      staleTime: 0,
    },
  )

  return (
    <Drawer anchor="right" open={isOpen} onClose={handleClose} sx={defaultDrawerSX}>
      {isOpen && (
        <DrawerContainer
          isOpen={isOpen}
          hasData={!!query.data}
          loading={query.isLoading}
          handleClose={handleClose}
        >
          <DrawerHeader handleClose={handleClose}>Search results:</DrawerHeader>

          {query.data?.rows?.length === 0 && (
            <FailPlaceholder error="No articles were found by entered parameters" mb={4} />
          )}
          <Grid container direction="row" spacing={2}>
            {query.data?.rows?.map((it: TimelineSearchResult) => {
              return (
                <Grid key={String(it.itemId)} item xs={12} sm={6} md={4}>
                  <SearchResultItem
                    title={it.post}
                    id={it.itemId}
                    type={it.itemType}
                    timelineId={it.timelineId}
                    status={it.status}
                  />
                </Grid>
              )
            })}
          </Grid>
        </DrawerContainer>
      )}
    </Drawer>
  )
}

export default SearchResultDrawer
