import { MediaFile } from './mediaFiles.types'

export enum EQuestionType {
  question = 'question',
  query = 'query',
}

export type Question = {
  id: number
  name: string
  stem: string
  instructions: string
  type: EQuestionType
  answers: string[]
  correctAnswer: string
  question: string
  explanation: string
  mediaFileId?: number | null
  mediaFile?: MediaFile | null
}

export type QuestionRequestCreate = Omit<Question, 'id'>

export interface QuestionAnswerRequest {
  id: number
  answer: string
}
