import EditorJS, { EditorConfig } from '@editorjs/editorjs'
import { OutputData } from '@editorjs/editorjs/types/data-formats/output-data'
// @ts-ignore
import Header from '@editorjs/header'
// @ts-ignore
import ImageTool from '@editorjs/image'
// @ts-ignore
import List from '@editorjs/list'
import { Box } from '@mui/material'
// @ts-ignore
import ColorPlugin from 'editorjs-text-color-plugin'
import React, { ForwardedRef, useEffect, useImperativeHandle, useRef } from 'react'
import { ShadowContainer } from '../ShadowContainer'
import ImageAndText from './image-and-text'
// @ts-ignore
import YoutubeEmbed from './youtube-embed'

interface Props {
  data?: OutputData | null
  preview: React.ReactNode
}

const elementId = 'editor'

const prepareBase64File = function (file: File) {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = (e) => {
      resolve(e.target?.result)
    }
  })
}

const imageToolConfig = {
  uploader: {
    uploadByFile(file: File) {
      const formData = new FormData()
      formData.append('image', file)

      return prepareBase64File(file).then((base64) => {
        return {
          success: 1,
          file: {
            url: base64,
            file,
          },
        }
      })
    },
  },
}

const baseConfig: EditorConfig = {
  holder: elementId,

  tools: {
    header: {
      class: Header,
      inlineToolbar: true,
    },
    image: {
      class: ImageTool,
      config: imageToolConfig,
    },
    list: {
      class: List,
      inlineToolbar: true,
    },
    youtubeEmbed: YoutubeEmbed,
    imageAndText: {
      class: ImageAndText,
      config: {
        ...imageToolConfig,
        descriptionPlaceholder: 'Please input text',
      },
    },
    Color: {
      class: ColorPlugin,
      config: {
        colorCollections: [
          '#FF1300',
          '#EC7878',
          '#9C27B0',
          '#673AB7',
          '#3F51B5',
          '#0070FF',
          '#03A9F4',
          '#00BCD4',
          '#4CAF50',
          '#8BC34A',
          '#CDDC39',
          '#FFF',
        ],
        defaultColor: '#FF1300',
        type: 'text',
        customPicker: true,
      },
    },
  },
}

export interface EditorAPI {
  getData: () => Promise<OutputData>
}

const Editor = (props: Props, ref: ForwardedRef<EditorAPI>) => {
  const { preview, data } = props
  const editor = useRef<EditorJS | null>(null)

  useEffect(() => {
    if (!editor.current) {
      editor.current = new EditorJS({
        ...baseConfig,
        data: data ?? undefined,
      })
    }

    return () => {
      if (editor.current && editor.current.destroy) {
        editor.current.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useImperativeHandle(
    ref,
    () => {
      return {
        getData: () => {
          if (!editor.current) {
            return Promise.reject([])
          }
          return editor.current?.save()
        },
      }
    },
    [],
  )

  return (
    <Box
      sx={{ minWidth: '100%', flexGrow: 1, display: 'flex' }}
      flexDirection="column"
      alignItems="center"
    >
      <ShadowContainer
        sx={{
          flexGrow: 1,
          background: '#fff',
          width: '100%',
          maxWidth: 800,
          paddingTop: 2,
          paddingX: 4,
          marginY: 'auto',
          paddingBottom: preview ? 8 : 2,
        }}
      >
        {preview}
        <div style={{ display: !preview ? 'block' : 'none' }} id={elementId} />
      </ShadowContainer>
    </Box>
  )
}

export default React.memo(React.forwardRef<EditorAPI, Props>(Editor))
