import { styled } from '@mui/material/styles'
import { Stack } from '@mui/material'

export const ShadowContainer = styled(Stack)(({ theme }) => {
  return ` 
    padding: 0 ${theme.spacing(1)};
    box-shadow: ${theme.shadows[6]};
    border-radius: ${theme.spacing(0.5)};
  `
})
