import React, { useMemo } from 'react'
import ImageUploading, { ResolutionType } from 'react-images-uploading'
import { ImageListType, ImageType } from 'react-images-uploading/dist/typings'
import { Button, Stack, Typography } from '@mui/material'

const Image = ({ image }: { image: ImageType; index: number }) => {
  return (
    <Stack spacing={0.5} alignItems="flex-start">
      <Typography color="textSecondary">Icon of Topic</Typography>
      <img src={image.dataURL} alt="" style={{ maxHeight: 60, width: 'auto' }} />
    </Stack>
  )
}

const ImageUpload = ({
  imgData,
  hasRatioRestrictions = false,
  onSave,
  error,
}: {
  imgData: string | ImageType | null
  onSave: (imgData: ImageType) => void
  error: string | null
  hasRatioRestrictions?: boolean
}) => {
  const images: ImageType[] = useMemo(() => {
    if (imgData) {
      return [
        {
          dataURL: typeof imgData === 'string' ? imgData : imgData.dataURL,
        },
      ]
    }
    return []
  }, [imgData])

  const onChange = (value: ImageListType) => {
    onSave(value[0] || null)
  }

  const ratioProps = hasRatioRestrictions
    ? { resolutionType: 'ratio' as ResolutionType, resolutionWidth: 16, resolutionHeight: 9 }
    : {}

  return (
    <ImageUploading
      value={images}
      onChange={onChange}
      acceptType={['jpg', 'jpeg', 'png']}
      {...ratioProps}
    >
      {({ imageList, onImageUpload, errors }) => (
        <Stack spacing={1} alignItems="flex-start">
          {imageList.map((image, index) => (
            <Image key={index} image={image} index={index} />
          ))}
          <Button variant="outlined" onClick={onImageUpload}>
            Click to upload icon
          </Button>
          {hasRatioRestrictions && errors?.resolution && (
            <Typography variant="caption" color="error">
              The image resolution is incorrect. It should be 16:9.
            </Typography>
          )}
          {error && (
            <Typography variant="caption" color="error">
              {error}
            </Typography>
          )}
        </Stack>
      )}
    </ImageUploading>
  )
}

export default ImageUpload
