import { LabelValue } from 'features/Metadata'
import { useMemo } from 'react'
import { Pathway, Response } from 'types'

export function usePathwayMetadata(response?: Response<Pathway>): LabelValue[] {
  return useMemo(() => {
    if (!response?.data) {
      return []
    }

    return [
      {
        label: 'Courses Total',
        value: response.data.countOfCourses,
      },
      {
        label: 'Query Questions Total',
        value: response.data.countOfQueries,
      },
      {
        label: 'Care Posts Total',
        value: response.data.countOfCareArticles,
      },
      {
        label: 'Task Posts Total',
        value: response.data.countOfTaskArticles,
      },
    ]
  }, [response])
}
