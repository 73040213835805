import { Box, Button, Stack, Typography } from '@mui/material'
import { useDrawersContext } from 'components/context/ClientsDrawersProvider'
import { UIItemContentContainer, UIPencilImg, UIStatusBadge } from 'features/UI'
import React, { SyntheticEvent } from 'react'
import { ETimelinePostStatus, User } from 'types'
import { getFromLocalStorage, USER_DATA } from 'utils'
import { TimelineItemHeader } from '../TimelineItemHeader/TimelineItemHeader'
import { TimelineLine } from '../TimelineLine/TimelineLine'

export function TimelineNoteItem({
  timelineId,
  id,
  title,
  authorId,
  authorName,
  date,
  text,
  status,
}: {
  timelineId: number
  id: number
  title: string
  authorId: number
  authorName: string
  date: string
  text: string
  status: ETimelinePostStatus
}) {
  const { handleOpenNoteView, handleOpenNoteEdit } = useDrawersContext()

  const onClickNoteTitle = () => {
    handleOpenNoteView(id, timelineId)
  }

  const onClickNoteEdit = (event: SyntheticEvent) => {
    event.preventDefault()
    event.stopPropagation()
    handleOpenNoteEdit(id)
  }

  const user = getFromLocalStorage(USER_DATA) as User | null

  return (
    <Stack direction="row" spacing={2}>
      <div style={{ position: 'relative', minWidth: 30, minHeight: '100%' }}>
        <UIPencilImg isOnTimeline />
        <TimelineLine />
      </div>

      <UIItemContentContainer onClick={onClickNoteTitle}>
        <TimelineItemHeader title={title} authorName={authorName} date={date} showAvatar={true} />
        <Typography paddingY={2}>{text}</Typography>
        <Stack direction="row" alignItems="center" spacing={1} justifyContent="space-between">
          <Box>{status !== ETimelinePostStatus.done && <UIStatusBadge status={status} />}</Box>
          <Stack direction="row" spacing={1}>
            {authorId === user?.id && (
              <Button size="small" variant="contained" onClick={onClickNoteEdit}>
                Edit
              </Button>
            )}
          </Stack>
        </Stack>
      </UIItemContentContainer>
    </Stack>
  )
}
