import { Stack, Typography } from '@mui/material'
import ModalWithTextAndIcon, {
  NameAndIconRequest,
} from 'components/modals/simple-modals/ModalWithTextAndIcon'
import { useChangeEntity } from 'hooks/useChangeEntity'
import React from 'react'
import { Nullish, Response } from 'types'
import { UIEntityItemBox } from '../UIEntityItemBox/UIEntityItemBox'

interface EntityProps {
  post: Nullish<string>
  name: string
  bannerMediaFileId: number | null
  bannerUrl: string | null
}

export function UIControlBanner<T extends EntityProps>({
  entityName,
  data,
  setData,
  setLoading,
  updateRequest,
}: {
  entityName: string
  data: Nullish<T>
  setData: (newData: T) => void
  setLoading: (value: boolean) => void
  updateRequest: (request: T) => Promise<Response<T>>
}) {
  const [handleChange, handleOpen, isOpen, handleClose] = useChangeEntity({
    setLoading,
    onSuccess: (data) => {
      setData(data)
    },
    requestFunc: updateRequest,
    warning: `Can't update ${entityName} Post`,
  })

  const handleFormChange = async (values: NameAndIconRequest) => {
    if (!data) return

    try {
      const request = { ...data, post: values.name, bannerMediaFileId: values.icon }
      await handleChange(request)
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  return (
    <>
      <UIEntityItemBox onEdit={handleOpen}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="body1" fontSize="1.125rem">
            {`${entityName} Banner`}
            {data?.post ? ` - ${data.post}` : ''}
          </Typography>

          {data?.bannerUrl && <img src={data.bannerUrl} alt="" style={{ height: '40px' }} />}
        </Stack>
      </UIEntityItemBox>

      <ModalWithTextAndIcon
        value={{
          name: data?.post || '',
          icon: data?.bannerUrl || null,
        }}
        title={`Change ${entityName} Banner`}
        label={`${entityName} Banner`}
        buttonText="Save"
        isOpen={isOpen}
        loading={false}
        setLoading={setLoading}
        hasRatioRestrictions
        handleClose={handleClose}
        onSubmit={handleFormChange}
      />
    </>
  )
}
