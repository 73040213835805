import Drawer from '@mui/material/Drawer'
import { getCareArticleById } from 'api/careArticles'
import { getLibraryArticleById } from 'api/learnArticles'
import { learnLibrarySetStatus as learnLibrarySetStatus } from 'api/learnLibrary'
import { getLessonById } from 'api/lessons'
import { setStatus } from 'api/timelines'
import NotificationSys from 'components/NotificationSystem'
import { defaultDrawerSX } from 'const'
import { LearnLibraryArticleStatusChanger } from 'features/LearnLibrary/components/LearnLibraryArticleStatusChanger/LearnLibraryArticleStatusChanger'
import { TimelineItemStatusChanger } from 'features/Timeline/components/TimelineItemStatusChanger/TimelineItemStatusChanger'
import { useEntityDataControl } from 'hooks/useEntityDataControl'
import React, { useCallback, useMemo } from 'react'
import { useMutation } from 'react-query'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { routes } from 'routes/routes'
import { useActions, useAppDispatch } from 'store/hooks'
import { selectLearnLibraryStatusFilter } from 'store/slice/learnLibraryFilters.slice'
import { metricClearUuid } from 'store/slice/metric.slice'
import { selectTagsFiltersIds } from 'store/slice/tagsFilters.slice'
import {
  allFiltersStatuses,
  ArticleType,
  CareArticle,
  ETimelinePostStatus,
  ETimelinePostType,
  IMetricType,
  LearnArticle,
  Lesson,
  Response,
} from 'types'
import { getRoute, useSearchParamsObject } from 'utils'
import DrawerContainer from '../../common/DrawerContainer'
import { ViewArticleDrawerContent } from './ViewArticleDrawerContent'

const convertArticleTypeToPostType = (articleType: ArticleType) => {
  switch (articleType) {
    case ArticleType.CareArticle:
      return ETimelinePostType.careArticle
    case ArticleType.Lesson:
      return ETimelinePostType.lessons
    case ArticleType.LearnLibrary:
      return ETimelinePostType.learnArticles
  }
}

const getLoadFunc = (type: ArticleType) => {
  switch (type) {
    case ArticleType.CareArticle:
      return getCareArticleById
    case ArticleType.LearnLibrary:
      return getLibraryArticleById
    case ArticleType.Lesson:
      return getLessonById
  }
}

const ViewArticleDrawer = ({
  isOpen,
  handleClose,
  id,
  type,
}: {
  id: number
  type: ArticleType
  isOpen: boolean
  handleClose: () => void
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const statusFilter = useSelector(selectLearnLibraryStatusFilter)
  const selectedTagIdsFilter = useSelector(selectTagsFiltersIds)

  const { fromId, fromType } = useSearchParamsObject()

  const { data, loading, setData } = useEntityDataControl({
    id,
    loadFunc: getLoadFunc(type) as () => Promise<Response<Lesson | LearnArticle | CareArticle>>,
    warning: "Can't load the data",
  })

  const {
    getTimelineById,
    getTimelineSilent,
    learnLibraryChangeStatus,
    getTopicsInReview,
    getTopics,
  } = useActions()

  const metricType = useMemo(() => {
    if (type === ArticleType.Lesson) {
      return IMetricType.lesson
    } else if (type === ArticleType.LearnLibrary) {
      return IMetricType.learnArticle
    } else if (type === ArticleType.CareArticle) {
      return IMetricType.careArticle
    }

    return undefined
  }, [type])

  const onChangeRoute = useCallback(
    (route: string) => {
      setData(null)
      navigate(route)
    },
    [navigate, setData],
  )

  const onDefaultClose = useCallback(
    (event?: object, reason?: 'backdropClick' | 'escapeKeyDown') => {
      if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
        return
      }

      if (metricType) {
        dispatch(metricClearUuid({ type: metricType }))
      }
      handleClose()
    },
    [dispatch, handleClose, metricType],
  )

  const onClose = useCallback(
    (shouldClearUuid: boolean) => {
      if (shouldClearUuid && metricType) {
        dispatch(metricClearUuid({ type: metricType }))
      }
      if (fromId && fromType) {
        const route = getRoute(routes.journeyItem, {
          journeyItemType: fromType,
          journeyItemId: fromId,
        })
        onChangeRoute(route)
        return
      }
      handleClose()
    },
    [dispatch, fromId, fromType, handleClose, metricType, onChangeRoute],
  )

  const setStatusMutation = useMutation(setStatus)

  const setLearnArticleStatus = useMutation(learnLibrarySetStatus, {
    onSuccess: ({ data }) => {
      onDefaultClose()
      getTimelineById({})
      getTopicsInReview()
      getTopics({
        status: statusFilter !== null ? statusFilter : allFiltersStatuses,
        selectedTagIds: selectedTagIdsFilter,
        silent: true,
      })

      if (data.status == 'updated') {
        NotificationSys.showSuccess(`Article successfully updated`)
      } else if (data.status == 'created') {
        NotificationSys.showSuccess(
          'Article is successfully added to the timeline with selected status',
        )
      }
    },
  })

  const handleSuccessStatusChange = (isSilent: boolean) => () => {
    if (!isSilent) {
      onDefaultClose()
      getTimelineById({})
      NotificationSys.showSuccess(`Article successfully updated`)
    } else {
      getTimelineSilent()
    }
  }

  const onUpdateStatus = (status: ETimelinePostStatus, isSilent: boolean) => {
    if (type === ArticleType.LearnLibrary) {
      setLearnArticleStatus.mutate(
        { id, status },
        {
          onSuccess: () => {
            learnLibraryChangeStatus({ learnArticleId: id, status })
          },
        },
      )
    } else {
      setStatusMutation.mutate(
        {
          id,
          status,
          type: convertArticleTypeToPostType(type),
        },
        {
          onSuccess: handleSuccessStatusChange(isSilent),
        },
      )
    }
  }

  return (
    <Drawer anchor="right" open={isOpen} onClose={onDefaultClose} sx={defaultDrawerSX}>
      <DrawerContainer
        isOpen={isOpen}
        hasData={!!data}
        loading={loading}
        handleClose={onDefaultClose}
      >
        {!loading && data && (
          <>
            <TimelineItemStatusChanger id={data.id} itemType={convertArticleTypeToPostType(type)} />

            {type === ArticleType.LearnLibrary && (
              <LearnLibraryArticleStatusChanger
                id={data.id}
                topicId={(data as LearnArticle).topicId}
              />
            )}

            <ViewArticleDrawerContent
              data={data}
              type={type}
              handleClose={onClose}
              onUpdateStatus={onUpdateStatus}
              onChangeRoute={onChangeRoute}
            />
          </>
        )}
      </DrawerContainer>
    </Drawer>
  )
}

export default ViewArticleDrawer
