import { getTimelineItemByEntity, setStatus } from 'api/timelines'
import { useEffect, useMemo, useRef } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useActions } from 'store/hooks'
import { ETimelinePostStatus, ETimelinePostType } from 'types'

export function TimelineItemStatusChanger({
  id,
  itemType,
}: {
  id: number
  itemType: ETimelinePostType
}) {
  const { getTimelineSilent } = useActions()
  const setStatusMutation = useMutation(setStatus)
  const isUpdated = useRef(false)

  const isTypeAccepted = useMemo(() => {
    const acceptedTypes = [
      ETimelinePostType.lessons,
      ETimelinePostType.careArticle,
      ETimelinePostType.taskArticle,
    ]

    return acceptedTypes.includes(itemType)
  }, [itemType])

  const { data: timelineItem, isFetched: isTimelineFetched } = useQuery(
    'timelineItem',
    () => {
      return getTimelineItemByEntity(id, itemType, {
        suppressErrorHandler: [404],
      })
    },
    {
      enabled: isTypeAccepted,
    },
  )

  const timelineStatus = useMemo(() => {
    return timelineItem?.data?.status
  }, [timelineItem])

  useEffect(() => {
    if (
      isTimelineFetched &&
      timelineStatus === ETimelinePostStatus.new &&
      !isUpdated.current &&
      isTypeAccepted
    ) {
      isUpdated.current = true
      setStatusMutation.mutate(
        {
          id,
          status: ETimelinePostStatus.review,
          type: itemType,
        },
        {
          onSuccess: () => {
            getTimelineSilent()
          },
        },
      )
    }
  }, [
    getTimelineSilent,
    id,
    isTimelineFetched,
    isTypeAccepted,
    itemType,
    setStatusMutation,
    timelineStatus,
  ])

  return null
}
