import React from 'react'
import ImageUpload from './ImageUpload'
import { Controller, useFormContext } from 'react-hook-form'

const FormImageUpload = ({
  name,
  hasRatioRestrictions,
}: {
  name: string
  hasRatioRestrictions?: boolean
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={null}
      render={({ field }) => (
        <ImageUpload
          onSave={field.onChange}
          imgData={field.value}
          error={(errors[name] ? errors[name]?.message : null) as string}
          hasRatioRestrictions={hasRatioRestrictions}
        />
      )}
    />
  )
}

export default FormImageUpload
