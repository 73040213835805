import { Button, Stack } from '@mui/material'
import React from 'react'
import { QuestionEditorFieldAnswer } from '../QuestionEditorFieldAnswer/QuestionEditorFieldAnswer'

export function QuestionEditorFieldAnswersList({
  answers,
  correctId,
  onChangeCorrectId,
  onChangeAnswers,
  maxLength,
  radio,
}: {
  maxLength: number
  answers: string[]
  correctId: number | null
  radio?: boolean
  onChangeAnswers: (answers: string[]) => void
  onChangeCorrectId: (correctId: number) => void
}) {
  const addNewOption = () => {
    onChangeAnswers([...answers, ''])
  }

  const onChangeAnswer = (idx: number, newText: string) => {
    const newAnswers = [...answers.slice(0, idx), newText, ...answers.slice(idx + 1)]
    onChangeAnswers(newAnswers)
  }

  const onRemoveOption = (idx: number) => {
    const newAnswers = [...answers.slice(0, idx), ...answers.slice(idx + 1)]
    onChangeAnswers(newAnswers)
  }

  return (
    <Stack spacing={2} alignItems="flex-start">
      <Button variant="contained" onClick={addNewOption}>
        Add Option
      </Button>

      <Stack spacing={2} width="100%">
        {answers.map((it, idx) => (
          <QuestionEditorFieldAnswer
            key={idx}
            canRemove={answers.length > 1}
            checked={idx == correctId}
            maxLength={maxLength}
            onChange={(value) => onChangeAnswer(idx, value)}
            onRemove={() => onRemoveOption(idx)}
            onSelect={() => onChangeCorrectId(idx)}
            radio={!!radio}
            value={it}
          />
        ))}
      </Stack>
    </Stack>
  )
}
