import { AxiosResponse } from 'axios'
import {
  BaseTableRequest,
  CareArticleRequestCreate,
  ApiSuccessResponse,
  CareArticle,
  PartialNull,
  RequestById,
  TableResponse,
  Response,
} from 'types'
import { prepareTableRequestFilterByNameAndLLId } from '../utils'
import axiosInstance from './axios'

export const getCareArticlesByName = async (name: string) => {
  return getCareArticles(prepareTableRequestFilterByNameAndLLId(name))
}

export const getCareArticles = async (request: BaseTableRequest) => {
  return axiosInstance.post<BaseTableRequest, TableResponse<CareArticle>>('/care-articles', request)
}

export const createCareArticle = async (
  request: CareArticleRequestCreate,
): Promise<Response<CareArticle>> => {
  return axiosInstance.post('/care-articles/create', request)
}

export const updateCareArticle = async (
  request: PartialNull<CareArticle>,
): Promise<Response<CareArticle>> => {
  const fixedRequest = {
    ...request,
    article: request.article || {
      blocks: [],
    },
  }
  return axiosInstance.post('/care-articles/update', fixedRequest)
}

export const getCareArticleById = async (request: RequestById): Promise<Response<CareArticle>> => {
  return axiosInstance.post('/care-articles/read', request)
}

export const deleteCareArticle = async (request: RequestById): Promise<unknown> => {
  return axiosInstance.post('/care-articles/delete', request)
}

export const duplicateCareArticle = async (request: {
  id: number
}): Promise<Response<{ id: number }>> => {
  return axiosInstance.post('/care-articles/duplicate', request)
}

export const checkCareArticleUniqueness = async (request: { name: string }): Promise<boolean> => {
  const result: AxiosResponse<ApiSuccessResponse> = await axiosInstance.post(
    '/care-articles/check-name',
    request,
  )

  return result.data.success
}
