import { Stack, Typography } from '@mui/material'
import { UICarePostArticleImg, UILearnArticleImg } from 'features/UI'
import moment from 'moment/moment'
import React from 'react'
import { ArticleType } from 'types'
import DrawerHeader from './common/DrawerHeader'

const articleAuthor = 'Memory Care Partner'

const formatDateForArticle = (date: string | Date) => {
  return moment(date).format('h:mm A dddd Do of MMMM, YYYY')
}

const getTextOfType = (type: ArticleType) => {
  switch (type) {
    case ArticleType.CareArticle:
      return 'Memory Care Article'
    case ArticleType.LearnLibrary:
      return 'Learn Library Article'
    case ArticleType.Lesson:
      return 'Lesson Article'
  }
}

interface Props {
  articleTitle: string
  date: string
  handleClose: () => void
  type: ArticleType
}

const ArticleHeader = (props: Props) => {
  const { articleTitle, handleClose, type, date } = props
  const formattedDate = formatDateForArticle(date)

  return (
    <>
      <DrawerHeader handleClose={handleClose} borderEnable={true}>
        {type === ArticleType.Lesson ? <UILearnArticleImg /> : <UICarePostArticleImg />}
        <span>{getTextOfType(type)}</span>
      </DrawerHeader>

      <Typography variant="h4" mt={-1} mb={0.5}>
        {articleTitle}
      </Typography>
      <Stack direction="row" sx={{ gap: '8px' }}>
        <Typography color="textSecondary" variant="body1">
          by {articleAuthor} · {formattedDate}
        </Typography>
      </Stack>
    </>
  )
}

export default ArticleHeader
